/* Styling calendar **/

.DayPicker-wrapper {
  outline: none;
  border: none;
}

.DayPicker-Body {
  background: repeating-linear-gradient(
    45deg,
    #979797,
    #979797 1px,
    rgba(255, 255, 255, 1) 1px,
    rgba(255, 255, 255, 1) 6px
  );
}

.DayPicker-Day {
  padding: 0;
  outline: none;
  border: none;
  border-radius: 0;
}

.DayPicker-Day--disabled {
  color: inherit;
}

.DayPicker-Month {
  width: 100%;
  margin: 0;
}

.DayPicker-WeekdaysRow {
  height: 40px;
  font-weight: normal;
  font-size: 18px;
}

.DayPicker-WeekdaysRow div:nth-last-child(1),
.DayPicker-WeekdaysRow div:nth-last-child(2) {
  background: rgba(70, 130, 90, 0.3);
}

.DayPicker-Caption div {
  text-align: center;
  color: rgba(50, 55, 50, 1);
  font-size: 26px;
}

.DayPicker:not(.DayPicker--interactionDisabled)
  .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
  background-color: initial;
}

.DayPickerInput {
  width: 100%;
}

.DayPickerInput > input:focus {
  outline: 0;
}

.DayPickerInput-Overlay {
  padding: 20px;
}

.DayPickerInput-Overlay .DayPicker-wrapper {
  padding-bottom: 0;
}

.DayPickerInput-Overlay .DayPicker-wrapper .DayPicker-Day {
  width: 50px;
  height: 45px;
}

.DayPickerInput-Overlay .DayPicker-wrapper .DayPicker-NavButton {
  top: 0;
}

.DayPickerInput-Overlay .DayPicker-wrapper .DayPicker-Caption div {
  text-align: left;
}
