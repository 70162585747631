/* Transitions */

.animation-wrapper {
  position: relative;
  height: 100vh;
}

/* Default animation */

.default-enter {
  opacity: 0.01;
}

.default-enter-active {
  opacity: 1;
  -webkit-transition: opacity 400ms;
  -moz-transition: opacity 400ms;
  -o-transition: opacity 400ms;
  transition: opacity 400ms;
}

.default-exit {
  opacity: 1;
}

.default-exit-active {
  opacity: 0.01;
  -webkit-transition: opacity 400ms;
  -moz-transition: opacity 400ms;
  -o-transition: opacity 400ms;
  transition: opacity 400ms;
}

/* Slide animation */

.slide-enter .slide-in {
  transform: translateX(100%);
}

.slide-enter-active .slide-in {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -o-transition: all 400ms;
  transition: all 400ms;
}

.slide-exit .slide-out {
  transform: translateX(0%);
}

.slide-exit-active .slide-out {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -o-transition: all 400ms;
  transition: all 400ms;
}

/* Entering home screen animation */

.home-enter .slide-in {
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.home-enter-active .slide-in {
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -o-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -o-transition: all 400ms;
  transition: all 400ms;
}

.home-exit .slide-out {
  transform: translateX(0%);
}

.home-exit-active .slide-out {
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(1000%);
  -webkit-transition: all 400ms;
  -moz-transition: all 400ms;
  -o-transition: all 400ms;
  transition: all 400ms;
}

/* Fade animation */

.fade-enter .fade-in {
  opacity: 0.01;
}

.fade-enter-active .home-sidebar {
  opacity: 0;
}

.fade-enter-active .fade-in {
  opacity: 1;
  -webkit-transition: opacity 300ms linear;
  -moz-transition: opacity 300ms linear;
  -o-transition: opacity 300ms linear;
  transition: opacity 300ms linear;
}

.fade-exit .fade-out {
  opacity: 1;
}

.fade-exit-active .fade-out {
  opacity: 0.01;
  -webkit-transition: opacity 300ms linear;
  -moz-transition: opacity 300ms linear;
  -o-transition: opacity 300ms linear;
  transition: opacity 300ms linear;
}

.fade-exit .home-sidebar {
  opacity: 0;
}

.fade-exit .mystique {
  opacity: 0;
}

.fade-exit .menu {
  opacity: 0;
}

/* Cancel click animation */

.cancel-enter .fade-in {
  opacity: 0.01;
}

.cancel-enter-active .fade-in {
  opacity: 1;
  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  transition: opacity 300ms;
}

.cancel-exit .fade-out {
  opacity: 1;
}

.cancel-exit-active .fade-out {
  opacity: 0.01;
  -webkit-transition: opacity 300ms;
  -moz-transition: opacity 300ms;
  -o-transition: opacity 300ms;
  transition: opacity 300ms;
}

.cancel-exit .fade-out {
  opacity: 0;
}

.cancel-exit .mystique {
  opacity: 0;
}

.cancel-exit .menu {
  opacity: 0;
}

/* Next click in purchase animation */

.next-enter-active .slide-up {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: slide-in-up 200ms, fade-in 200ms;
  -moz-animation: slide-in-up 200ms, fade-in 200ms;
  -o-animation: slide-in-up 200ms, fade-in 200ms;
  animation: slide-in-up 200ms, fade-in 200ms;
}

.next-exit-active .slide-up {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: slide-out-up 200ms, fade-out 200ms;
  -moz-animation: slide-out-up 200ms, fade-out 200ms;
  -o-animation: slide-out-up 200ms, fade-out 200ms;
  animation: slide-out-up 200ms, fade-out 200ms;
}

.next-exit {
  opacity: 0;
}

/* Navigate back in purchase animation */

.prev-enter-active .slide-down {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: slide-in-down 200ms, fade-in 200ms;
  -moz-animation: slide-in-down 200ms, fade-in 200ms;
  -o-animation: slide-in-down 200ms, fade-in 200ms;
  animation: slide-in-down 200ms, fade-in 200ms;
}

.prev-exit-active .slide-down {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: slide-out-down 200ms, fade-out 200ms;
  -moz-animation: slide-out-down 200ms, fade-out 200ms;
  -o-animation: slide-out-down 200ms, fade-out 200ms;
  animation: slide-out-down 200ms, fade-out 200ms;
}

.prev-exit {
  opacity: 0;
}

/* Click on change in summary animation */

.change-enter-active {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-animation: slide-in-down 200ms, fade-in 200ms;
  -moz-animation: slide-in-down 200ms, fade-in 200ms;
  -o-animation: slide-in-down 200ms, fade-in 200ms;
  animation: slide-in-down 200ms, fade-in 200ms;
}

.change-exit-active {
  position: absolute;
  opacity: 1;
  width: 100%;
  height: 100%;
  -webkit-animation: slide-out-down 200ms, fade-out 200ms;
  -moz-animation: slide-out-down 200ms, fade-out 200ms;
  -o-animation: slide-out-down 200ms, fade-out 200ms;
  animation: slide-out-down 200ms, fade-out 200ms;
}

.change-exit {
  opacity: 0;
}

@keyframes slide-in {
  0% {
    left: 100%;
    top: 0px;
  }
  100% {
    left: 0px;
    top: 0px;
  }
}

@keyframes slide-out {
  0% {
    left: 0;
    top: 0px;
  }
  100% {
    left: -100%;
    top: 0px;
  }
}

@keyframes slide-in-right {
  0% {
    right: 100%;
    top: 0px;
  }
  100% {
    right: 0px;
    top: 0px;
  }
}

@keyframes slide-out-right {
  0% {
    right: 0;
    top: 0px;
  }
  100% {
    right: -100%;
    top: 0px;
  }
}

@-webkit-keyframes slide-in-up {
  0% {
    left: 0;
    top: 30%;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-moz-keyframes slide-in-up {
  0% {
    left: 0;
    top: 30%;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-o-keyframes slide-in-up {
  0% {
    left: 0;
    top: 30%;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes slide-in-up {
  0% {
    left: 0;
    top: 30%;
  }
  100% {
    left: 0;
    top: 0;
  }
}

@-webkit-keyframes slide-out-up {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0;
    top: -70%;
  }
}
@-moz-keyframes slide-out-up {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0;
    top: -70%;
  }
}
@-o-keyframes slide-out-up {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0;
    top: -70%;
  }
}
@keyframes slide-out-up {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0;
    top: -70%;
  }
}

@-webkit-keyframes slide-in-down {
  0% {
    left: 0;
    top: -30%;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-moz-keyframes slide-in-down {
  0% {
    left: 0;
    top: -30%;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@-o-keyframes slide-in-down {
  0% {
    left: 0;
    top: -30%;
  }
  100% {
    left: 0;
    top: 0;
  }
}
@keyframes slide-in-down {
  0% {
    left: 0;
    top: -30%;
  }
  100% {
    left: 0;
    top: 0;
  }
}

@-webkit-keyframes slide-out-down {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0;
    top: 70%;
  }
}
@-moz-keyframes slide-out-down {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0;
    top: 70%;
  }
}
@-o-keyframes slide-out-down {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0;
    top: 70%;
  }
}
@keyframes slide-out-down {
  0% {
    left: 0;
    top: 0;
  }
  100% {
    left: 0;
    top: 70%;
  }
}

@-webkit-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-webkit-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fade-out {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
