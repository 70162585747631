@import url('animation.css');

:root {
  --background: #fff;
  --color: #000;
}

html,
body {
  background: var(--background);
  color: var(--color);
  height: 100%;
}

input:-webkit-autofill {
  box-shadow: 0 0 0 30px #fdfcf9 inset;
  -webkit-box-shadow: 0 0 0 30px #fdfcf9 inset;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@font-face {
  font-family: barlow;
  src: url(../../fonts/barlow/barlow-regular.woff2) format('woff2'),
    url(../../fonts/barlow/barlow-regular.woff) format('woff'),
    url(../../fonts/barlow/barlow-regular.ttf) format('truetype');
}

@font-face {
  font-family: barlow-semi-condensed;
  src: url(../../fonts/barlow-semi-condensed/barlow-semi-condensed-regular.woff2) format('woff2'),
    url(../../fonts/barlow-semi-condensed/barlow-semi-condensed-regular.woff) format('woff'),
    url(../../fonts/barlow-semi-condensed/barlow-semi-condensed-regular.ttf) format('truetype');
}

@font-face {
  font-family: barlow-semi-condensed-medium;
  src: url(../../fonts/barlow-semi-condensed/barlow-semi-condensed-500.woff2) format('woff2'),
    url(../../fonts/barlow-semi-condensed/barlow-semi-condensed-500.woff) format('woff'),
    url(../../fonts/barlow-semi-condensed/barlow-semi-condensed-500.ttf) format('truetype');
}

@font-face {
  font-family: barlow-condensed;
  src: url(../../fonts/barlow-condensed/barlow-condensed-regular.woff2) format('woff2'),
    url(../../fonts/barlow-condensed/barlow-condensed-regular.woff) format('woff'),
    url(../../fonts/barlow-condensed/barlow-condensed-regular.ttf) format('truetype');
}

@font-face {
  font-family: vollkorn;
  src: url(../../fonts/vollkorn/vollkorn-regular.woff2) format('woff2'),
    url(../../fonts/vollkorn/vollkorn-regular.woff) format('woff'),
    url(../../fonts/vollkorn/vollkorn-regular.ttf) format('truetype');
}

/* Card animation */

@keyframes rotate {
  0% {
    transform: rotateY(0deg);
  }
  100% {
    transform: rotateY(180deg);
  }
}

@keyframes rotate-inverse {
  0% {
    transform: rotateY(180deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

@keyframes slidein {
  0% {
    transform: translate(100%);
  }
  100% {
    transform: translate(0%);
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Internet Explorer */
@-ms-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/** Styling dropdown menu */

.Select-control {
  border: none;
  background: rgba(253, 252, 249, 1);
}

.Select-value {
  background-color: rgba(253, 252, 249, 1);
}

.Select-control .Select-input:focus {
  background: rgba(253, 252, 249, 1);
}

.Select-option {
  background-color: rgba(253, 252, 249, 1);
}

.Select-multi-value-wrapper {
  background-color: rgba(253, 252, 249, 1);
}

.Select-arrow-zone {
  background-color: rgba(253, 252, 249, 1);
}

/** Styling toastr box */

.error_toast {
  background-color: rgba(189, 9, 9, 1);
  opacity: 1 !important;
  font-family: 'barlow-semi-condensed';
  font-size: 16px;
  font-weight: 500;
}
